import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import Nope from "nope-validator";
import { useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import { HiClipboardCheck, HiOutlineSave, HiPencilAlt } from "react-icons/hi";
import { nopeResolver } from "@hookform/resolvers/nope";

import { API_URL } from "@/redux-client/api";

import DatepickerInput from "@/components/form/DatepickerInput";
import Loading from "@/components/common/Loading";
import Button from "@/components/common/Button";
import TimePickerInput from "@/components/form/TimePickerInput";
import Notification from "@/components/common/Notification";
import DataTextDisplay from "@/components/common/DataTextDisplay";
import { TiCancel } from "react-icons/ti";
import Modal from "../common/Modal";
import ModalFooterButton from "../common/ModalFooterButton";
import { useSelector } from "react-redux";

const SubTitleText = ({ label, isEditable, isLoading, editMode, onEdit, onSave, onCancel }) => {
  return (
    <div className="mb-3 bg-slate-200 px-5 py-3 rounded-lg flex justify-between items-center">
      <h2 className="font-bold text-xl">{label}</h2>
      { isEditable ? 
        editMode ? 
          isLoading ? 
          <div>
            <Loading size={15} />
          </div>
        : <div className="flex gap-2">
          <button 
            onClick={onSave}
            className="bg-orange-600 text-white rounded-lg px-3 py-2 font-semibold flex items-center mb-0 justify-center">
            <HiOutlineSave size={17} className="mr-1" />
            <label className="cursor-pointer">Simpan</label>
          </button>
          <button 
            onClick={onCancel}
            className="text-red-600 border border-1 border-red-600 rounded-lg px-3 py-2 font-semibold flex items-center mb-0 justify-center cursor-pointer">
            Batal
          </button>
        </div>
        : <button 
          onClick={onEdit}
          className="bg-orange-600 text-white rounded-lg px-3 py-2 font-semibold flex items-center mb-0 justify-center"
        >
        <HiPencilAlt size={17} className="mr-1" />
        <label>Ubah Jadwal</label>
      </button>
      : null }
    </div>
  );
};

const NegotiationDetails = ({ booking_code }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [notification, setNotification] = useState(null);
  const [cancelNotification, setCancelNotification] = useState(null);

  const [data, setData] = useState(null);
  const [isConfirmAvailable, setIsConfirmAvailable] = useState(false);

  const EditSchema = useMemo(() => {
    const editDynamicSchema = {};

    editDynamicSchema["negotiation_time"] = Nope.string().required("Masukkan waktu negosiasi");
    editDynamicSchema["negotiation_date"] = Nope.string().required("Masukkan tanggal negosiasi");

    return Nope.object().shape(editDynamicSchema);
  }, []);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: nopeResolver(EditSchema),
    defaultValues: {
      negotiation_date: null,
      negotiation_time: null
    }
  });

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${API_URL}/negotiation/${booking_code}`
      );
      if (response.status === 200) {
        const { data } = response.data;
        setData(data);
        setValue("negotiation_date", {
          startDate: moment(data.negotiation_date, "DD/MM/YYYY").format('YYYY-MM-DD'),
          endDate: moment(data.negotiation_date, "DD/MM/YYYY").format('YYYY-MM-DD'),
        })

        const negotiationTime = data.negotiation_time.split(" ")[0];
        setValue("negotiation_time", dayjs(negotiationTime, "hh.mm"));

        const today = moment().format("YYYY-MM-DD");
        const nego_date = moment(data.negotiation_date, "DD/MM/YYYY").format("YYYY-MM-DD");
        setIsConfirmAvailable(moment(today).isSameOrAfter(nego_date));
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data;
        setNotification({
          isError: true,
          title: "Gagal menyimpan data. Mohon coba beberapa saat lagi.",
          text: message,
        });
      }

      setLoading(false);

      setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
  }, [booking_code, setValue]);

  useEffect(() => {
    if (booking_code) fetchData();
    return () => {};
  }, [fetchData, booking_code]);

  const onSubmit = async (formData) => {
    if(formData.startDate === null && formData.endDate === null) {
      setError('negotiation_date', { type: 'custom', message: 'Pilih tanggal negosiasi' });
    } else {
      const dataPayload = {
        _id: data._id,
        booking_code,
        negotiation_date: moment(formData.negotiation_date.startDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        negotiation_time: `${dayjs(formData.negotiation_time).format("HH.mm")} WIB`,
      };

      try {
        setLoadingEdit(true);
        const response = await axios.post(
          `${API_URL}/negotiation/change-date-time`,
          dataPayload
        );
        if (response.status === 200) {
          fetchData();
          setEditMode(false);
          setLoadingEdit(false);
        }
      } catch (error) {
        if (error?.response?.data) {
          const { message } = error.response.data;
          setNotification({
            isError: true,
            title: "Gagal menyimpan data. Mohon coba beberapa saat lagi.",
            text: message,
          });
        }
  
        setLoadingEdit(false);
  
        setTimeout(() => {
          setNotification(null);
        }, 2000);
      }
    };
  };

  const onCancelBooking = async() => {
    const dataPayload = {
      booking_code: booking_code,
      admin_id: user._id
    };

    try {
      setCancelLoading(true);
      setCancelNotification(null);

      const response = await axios.post(
        `${API_URL}/booking/cancel`,
        dataPayload
      );
      if (response.status === 200) {
        setCancelNotification({
          isError: false,
          title: "Pembatalan Berhasil",
          text: "Pesanan berhasil dibatalkan",
        });
        
        setTimeout(() => {
          setCancelLoading(false);
          setCancelModalOpen(false);
          navigate("/jadwal-negosiasi");
        }, 2000);
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data;
        setCancelNotification({
          isError: true,
          title: "Pembatalan Gagal",
          text: message,
        });
      }

      setCancelLoading(false);

      setTimeout(() => {
        setCancelNotification(null);
      }, 2000);
    }
  };

  if (loading)
    return (
      <div className="h-[68vh] flex items-center justify-center">
        <Loading type="beat-loader" />
      </div>
    );

  if (data) {
    return (
      <div className="min-h-[68vh]">
        {notification && 
          <Notification notification={notification} />
        }
        <div>
          <SubTitleText
            label="Data Booking"
            isEditable={true}
            editMode={editMode}
            isLoading={loadingEdit}
            onEdit={() => setEditMode(true)}
            onCancel={() => setEditMode(false)}
            onSave={handleSubmit(onSubmit)}
          />
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 px-2 py-2">
            <DataTextDisplay label="Kode Booking" value={data.booking_code} />
            <DataTextDisplay
              label="Tanggal Booking"
              value={moment(data.createdDate, "MM/DD/YYYY, HH:mm:ss").format(
                "DD MMMM YYYY"
              )}
            />
            {editMode ? (
              <Controller
                control={control}
                name="negotiation_date"
                defaultValue={watch("negotiation_date")}
                render={({ field: { onChange, value } }) => (
                  <DatepickerInput
                    name="negotiation_date"
                    onChange={onChange}
                    value={value}
                    label="Tanggal Negosiasi"
                    disabled={loadingEdit}
                    error={errors?.negotiation_date}
                  />
                )}
              />
            ) : (
              <DataTextDisplay
                label="Tanggal Negosiasi"
                value={moment(data.negotiation_date, "DD/MM/YYYY").format(
                  "DD MMMM YYYY"
                )}
              />
            )}
            {editMode ? (
              <TimePickerInput
                control={control}
                name="negotiation_time"
                label="Waktu Negosiasi"
                labelClass="mb-2"
                disabled={loadingEdit}
                errors={errors}
                className="md:(max-w-[calc(50%-12px)] ml-auto)"
              />
            ) : (
              <DataTextDisplay
                label="Waktu Negosiasi"
                value={data.negotiation_time}
              />
            )}
          </div>
        </div>
        <div className="mt-4">
          <SubTitleText label="Data Pemesan" />
          <div className="grid grid-cols-2 md:grid-cols-4 px-2 py-2">
            <DataTextDisplay label="Nama" value={data.customer_id.name} />
            <DataTextDisplay label="Email" value={data.customer_id.email} />
            <DataTextDisplay label="No. HP" value={data.customer_id.phone} />
          </div>
        </div>
        <div className="mt-4">
          <SubTitleText key="event_data" label="Data Acara" />
          <div className="grid grid-cols-2 md:grid-cols-4 px-2 py-2">
            <DataTextDisplay
              label="Jenis Acara"
              value={data.event_data.event_type}
            />
            <DataTextDisplay
              label="Tanggal Acara"
              value={moment(data.event_data.event_date, "DD/MM/YYYY").format(
                "DD MMMM YYYY"
              )}
            />
            <DataTextDisplay
              label="Lokasi Acara"
              value={data.event_data.event_location}
            />
            <DataTextDisplay
              label="Keterangan Tambahan"
              value={data.event_data.event_note}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <button 
            onClick={() => setCancelModalOpen(true)}
            className="bg-red-600 text-white rounded-xl font-semibold pl-5 pr-6 py-4 flex items-center mt-10 mb-0 w-full justify-center"
          >
            <TiCancel size={22} className="mr-1" />
            <label>Batalkan Pesanan</label>
          </button>
          {isConfirmAvailable &&
            <button 
              onClick={() =>
								navigate(`/negotiation-confirmation`, {
									state: {
                    booking_code
                  },
								})
							}
              className="bg-orange-600 text-white rounded-xl font-semibold pl-5 pr-6 py-4 flex items-center mt-10 mb-0 w-full justify-center"
            >
              <HiClipboardCheck size={17} className="mr-1" />
              <label>Konfirmasi Pesanan</label>
            </button>
          }
        </div>
        <Modal
          title='Batalkan Pesanan'
          isOpen={cancelModalOpen}
          showCloseButton={true}
          onClose={() => setCancelModalOpen(false)}
        >
          <p>Apakah anda yakin untuk lanjut membatalkan pesanan dengan Kode Booking {booking_code}?</p>
          <Notification notification={cancelNotification} />
          <ModalFooterButton
            loading={cancelLoading}
            isSubmitDelete={true}
            submitLabel='Batalkan Pesanan'
            cancelLabel='Kembali'
            onSubmit={onCancelBooking}
            onCancel={() => setCancelModalOpen(false)}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className="h-[68vh] flex items-center justify-center flex-col">
      <p className="text-xl font-semibold">Data tidak ditemukan</p>
      <Button onClick={() => navigate("/jadwal-negosiasi")} className="mt-8">
        <i className="mr-2">
          <IoChevronBack />
        </i>
        <span>Lihat tabel jadwal negosiasi</span>
      </Button>
    </div>
  );
};

export default NegotiationDetails;
