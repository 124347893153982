const rupiahFormat = (value) => {
  if(typeof(value) !== 'number') {
      return '';
  }

try {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  }).format(value);
} catch {
  return '';
}
};

const currencyInputSeparator = (value) => {
  const addCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
    
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  return addCommas(removeNonNumeric(value))
}

export { rupiahFormat, currencyInputSeparator };
