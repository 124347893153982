import SelectInput from '@/components/form/SelectInput';
import { useMemo } from 'react';

// type TableFilterSelectType = {
// 	className?: string;
// 	onChange: () => void;
// 	options: ReactSelectValue[];
// 	filter: ReactSelectValue | null;
// };

const TableFilterSelect = ({ className = '', onChange, options, filter }) => {
	const valueFilter = useMemo(() => {
		if (filter) {
			return filter;
		}

		return { value: 'ALL', label: 'Semua' };
	}, [filter]);

	return (
		<SelectInput
			styles={{
				// ...styles,
				option: (base, state) => ({
					...base,
					fontSize: '14px',
					color: state.isFocused ? '#000000' : state.isSelected ? '#ffffff' : base.color,
					backgroundColor: state.isSelected ? '#EA580B' : base.color,
					'&:hover': {
						backgroundColor: state.isSelected ? '#EA580B' : '#EA580B19',
						color: state.isSelected ? '#ffffff' : '#000000',
					},
					transition: '0.2s all ease-in-out',
				}),
				valueContainer: (base, state) => ({
					...base,
					height: '50px',
					top: '-4px',
				}),
				control: (base, state) => ({
					...base,
					borderColor: state.isFocused ? 'hsl(0, 0%, 90%)' : base.borderColor,
					boxShadow: 'none',
					borderRadius: '1.25rem',
					height: '50px',
					paddingLeft: 12,
					paddingRight: 12,
					fontSize: 14,
					'&:hover': {
						borderColor: state.isFocused ? 'hsl(0, 0%, 90%)' : base.borderColor,
					},
				}),
				indicatorsContainer: (base, props) => ({
					...base,
				}),
			}}
			value={valueFilter}
			onChange={onChange}
			options={options}
			parentClass={className}
		/>
	);
};

export default TableFilterSelect;
