import ReactTable from 'react-table';

const CustomTable = ({
	pages,
	tableKey,
	isLoading,
	data,
	columns,
	onFetchData,
	showTableHeader,
	wrapperClassName,
	headerComponent,
	dataPerPage,
	showPaginationBottom = true,
}) => {
	return (
		<div className='card'>
			<div className='card-body'>
				{showTableHeader ? (
					<div className='pb-4 mb-3 border-bottom'>{headerComponent ? headerComponent : null}</div>
				) : null}
				<div className='dataTables_wrapper mt-8'>
					<ReactTable
						filterable
						pages={pages}
						manual
						key={tableKey}
						loading={isLoading}
						data={data}
						columns={columns}
						pageSize={dataPerPage || 20}
						className={`-striped -highlight ${wrapperClassName}`}
						onFetchData={onFetchData}
						showPaginationBottom={showPaginationBottom}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomTable;
