
import React from 'react'
import { IoChevronBack } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import NegotiationDetails from '@/components/negotiation/NegotiationDetails';

const NegotiationBookingDetailsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className='bg-white text-center w-full py-5 rounded-lg relative'>
				<h1 className='text-2xl font-bold'>Detail Pemesanan</h1>
        <button onClick={() => navigate('/jadwal-negosiasi')} className='absolute left-[10px] top-[29%]'>
          <IoChevronBack size={30} />
        </button>
			</div>

      <div className='bg-white w-full p-5 mt-5 rounded-lg'>
				<NegotiationDetails booking_code={state?.booking_code} />
			</div>
    </div>
  )
}

export default NegotiationBookingDetailsPage