import {
	HiCog,
	HiOutlineViewGrid,
} from 'react-icons/hi';
import { HiUserPlus } from 'react-icons/hi2';
import { sidebarMenus } from '@/static/sidebarMenus';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IoCalendarOutline, IoListCircleOutline } from 'react-icons/io5';
import { BiFoodMenu } from "react-icons/bi";

const Sidebar = () => {
	const { isAuthenticated } = useSelector((state) => state.auth);

	return (
		<div className='flex flex-col items-center w-56 h-full overflow-hidden text-gray-700 bg-white'>
			<div className='w-full px-2'>
				<div className='flex flex-col items-center w-full border-gray-300'>
					{isAuthenticated
						? sidebarMenus.map((item, idx) => (
								<NavLink
									key={`sidebar-menu-${idx}`}
									to={item.path}
									className={({ isActive }) => {
										let styleColor = 'bg-transparent hover:bg-orange-50';

										if (isActive) {
											styleColor = 'bg-orange-600 text-white';
										}

										return `flex items-center w-full h-12 px-3 mt-2 rounded ${styleColor}`;
									}}
								>
									<span>
										{item.label.toLowerCase() === 'dashboard' ? (
											<HiOutlineViewGrid size={25} />
										) : item.label.toLowerCase() === 'negosiasi' ? (
											<IoCalendarOutline size={25} />
										) : item.label.toLowerCase() === 'booking' ? (
											<IoListCircleOutline size={25} />
										) : item.label.toLowerCase() === 'package' ? (
											<BiFoodMenu size={25} />
										) : item.label.toLowerCase() === 'admins' ? (
											<HiUserPlus size={25} />
										) : item.label.toLowerCase() === 'settings' ? (
											<HiCog size={25} />
										) : null}
									</span>
									<span className='ml-3 text-md font-medium'>{item.label}</span>
								</NavLink>
						  ))
						: null}
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
