import axios from 'axios';
import { IoAdd } from 'react-icons/io5';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HiOutlineTrash, HiPencilAlt } from 'react-icons/hi';

import { AddFormAdmin } from './FormAdmin';

import { adminRoleOptions } from '@/static/data';
import { API_URL } from '@/redux-client/api';

import CustomTable from '@/components/table/CustomTable';
import TableFilterSelect from '@/components/common/TableFilterSelect';
import Notification from '@/components/common/Notification';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import ModalFooterButton from '@/components/common/ModalFooterButton';

const TableHeader = ({ totalAdmins, openAddModalHandler }) => {
	return (
		<div className='flex items-center justify-between mt-6'>
			<p className='mb-0 text-black'>
				Total : <strong>{totalAdmins}</strong> admin
			</p>
			<Button onClick={openAddModalHandler}>
				<i className='mr-2'>
					<IoAdd />
				</i>
				<span>Tambah Admin</span>
			</Button>
		</div>
	);
};

const TableAdmin = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);
	const [pages, setPages] = useState(0);
	const [data, setData] = useState([]);
	const [totalAdmins, setTotalAdmins] = useState(0);
	const [tableKey, setTableKey] = useState(null);
	const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
	const [isDeleteAdminModalOpen, setIsDeleteAdminModalOpen] = useState(false);
	const [selectedAdmins, setSelectedAdmin] = useState(null);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTableKey(Date.now());

		return () => setTableKey(Date.now());
	}, []);

	const fetchAdmins = useCallback(async (page, filtered, sorted, pageSize) => {
		setIsLoading(true);
		const payload = {
			pagination: {
				limit: pageSize,
				activePage: page,
			},
			sort: sorted,
			filters: filtered,
		};

		try {
			const response = await axios.post(`${API_URL}/admin/list`, payload);

			if (response.status === 200) {
				const { data, totalData, totalPage } = response.data.message;
				setData(data);
				setPages(totalPage);
				setTotalAdmins(totalData);
				setIsLoading(false);
			} else {
				const { statusText } = response;
				setNotification({
					title: statusText,
					text: 'Please try again later.',
					isError: true,
				});
			}
		} catch (error) {
			console.log(error);
			setNotification({
				title: 'Network error!',
				text: 'Please try again later.',
				isError: true,
			});
		}
	}, []);

	const onCloseModal = useCallback(() => {
		setIsDeleteAdminModalOpen(false);
		setIsAddAdminModalOpen(false);
		fetchAdmins(0, [], [], 20);
	}, [fetchAdmins]);

	const onDeleteAdmin = useCallback(async() => {
		try {
			setIsLoading(true);
			const response = await axios.delete(`${API_URL}/admin/${selectedAdmins._id}`);
			if (response.status === 200) {
				const { message } = response.data;
				onCloseModal();
				setNotification({
					isError: false,
					title: 'Sukses',
					text: message,
				});

				setIsLoading(false);

				setTimeout(() => {
					setNotification(null);
				}, 2000);
			}
		} catch (error) {
			console.log('error', error);
			onCloseModal();
			if (error?.response?.data) {
				const { message } = error.response.data;
				setNotification({
					isError: true,
					title: 'Error',
					text: message,
				});
			}

			setIsLoading(false);

			setTimeout(() => {
				setNotification(null);
			}, 2000);
		}
	}, [onCloseModal, selectedAdmins]);

	const tableColumns = useMemo(
		() => [
			{
				Header: 'Nama',
				accessor: 'name',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'Email',
				accessor: 'email',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'No. HP',
				accessor: 'phone',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'Role',
				accessor: 'role',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14 capitalize'>{value ? value.split('-').join(' ') : '-'}</p>;
				},
				Filter: ({ filter, onChange }) => (
					<TableFilterSelect
						filter={filter?.value}
						onChange={onChange}
						options={[{ value: 'ALL', label: 'Semua' }, ...adminRoleOptions]}
					/>
				),
			},
			{
				Header: '',
				accessor: '_id',
				minWidth: 180,
				filterable: false,
				sortable: false,
				Cell: ({ row }) => (
					<div className='flex items-center justify-between'>
						<Button
							buttonType='info'
							buttonSize='sm'
							rounded={false}
							onClick={() =>
								navigate(`${pathname}/details`, {
									state: row._original,
								})
							}
						>
							<HiPencilAlt
								size={14}
								className='mr-1'
							/>
							Detail
						</Button>
						<Button
							buttonType='error'
							buttonSize='circle-sm'
							className='!px-2 ml-2'
							onClick={() => {
								setSelectedAdmin({
									_id: row._original._id,
									name: row._original.name,
								});
								setIsDeleteAdminModalOpen(true);
							}}
						>
							<HiOutlineTrash size={16} />
						</Button>
					</div>
				),
				style: { textAlign: 'center' },
			},
		],
		[navigate, pathname]
	);

	return (
		<>
			<Notification notification={notification} />
			<CustomTable
				wrapperClassName='p-4'
				pages={pages}
				tableKey={tableKey}
				isLoading={isLoading}
				data={data}
				columns={tableColumns}
				onFetchData={({ page, filtered, sorted, pageSize }) => {
					fetchAdmins(page, filtered, sorted, pageSize);
				}}
				showTableHeader={true}
				headerComponent={
					<TableHeader
						totalAdmins={totalAdmins}
						openAddModalHandler={() => setIsAddAdminModalOpen(true)}
					/>
				}
			/>
			<Modal
				title='Tambah Admin'
				isOpen={isAddAdminModalOpen}
				showCloseButton={true}
				onClose={onCloseModal}
			>
				<AddFormAdmin closeModalHandler={onCloseModal} />
			</Modal>
			<Modal
				title='Delete Akun'
				isOpen={isDeleteAdminModalOpen}
				showCloseButton={true}
				onClose={onCloseModal}
			>
				<p>Hapus akun {selectedAdmins?.name}?</p>
				<ModalFooterButton
					loading={isLoading}
					isSubmitDelete={true}
					submitLabel='Delete'
					onSubmit={onDeleteAdmin}
					onCancel={onCloseModal}
				/>
			</Modal>
		</>
	);
};

export default TableAdmin;
