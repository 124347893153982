import axios from 'axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "moment/locale/id";
import { HiPencilAlt } from 'react-icons/hi';

import { API_URL } from '@/redux-client/api';

import CustomTable from '@/components/table/CustomTable';
import Notification from '@/components/common/Notification';
import Button from '@/components/common/Button';

// filterProps = "hari" | "besok" | "minggu" | "bulan";

const TableNegotiation = ({ filterProps }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);
	const [pages, setPages] = useState(0);
	const [data, setData] = useState([]);
	const [tableKey, setTableKey] = useState(null);
	// const [selectedData, setSelectedData] = useState(null);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTableKey(Date.now());

		return () => setTableKey(Date.now());
	}, []);
  

	const fetchData = useCallback(async(page, filtered, sorted, pageSize) => {
    setIsLoading(true);
		const payload = {
			pagination: {
				limit: pageSize,
				activePage: page,
			},
			sort: sorted,
			filters: filtered,
      filterDate: filterProps
		};

		try {
			const response = await axios.post(`${API_URL}/negotiation/list`, payload);

			if (response.status === 200) {
				const { data, totalPage } = response.data.message;
				setData(data);
				setPages(totalPage);
				setIsLoading(false);
			} else {
				const { statusText } = response;
				setNotification({
					title: statusText,
					text: 'Please try again later.',
					isError: true,
				});
			}
		} catch (error) {
			console.log(error);
			setNotification({
				title: 'Network error!',
				text: 'Please try again later.',
				isError: true,
			});
		}
	}, [filterProps]);

  useEffect(() => {
    fetchData(0, [], {}, 5, filterProps);
    return () => {}
  }, [filterProps, fetchData]);

	const tableColumns = useMemo(
		() => [
			{
				Header: 'Kode Booking',
				accessor: 'booking_code',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'Tanggal Negosiasi',
				accessor: 'negotiation_date',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{moment(value, "DD/MM/YYYY").format("DD MMMM YYYY")}</p>;
				},
			},
			{
				Header: 'Waktu Negosiasi',
				accessor: 'negotiation_time',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'Acara',
				accessor: 'event_data.event_type',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14 capitalize'>{value ? value.split('-').join(' ') : '-'}</p>;
				},
			},
			{
				Header: '',
				accessor: '_id',
				minWidth: 180,
				filterable: false,
				sortable: false,
				Cell: ({ row }) => (
					<div className='flex items-center justify-between'>
						<Button
							buttonType='info'
							buttonSize='sm'
							rounded={false}
							onClick={() =>
								navigate(`${pathname}/details`, {
									state: row._original,
								})
							}
						>
							<HiPencilAlt
								size={14}
								className='mr-1'
							/>
							Detail & Edit
						</Button>
					</div>
				),
				style: { textAlign: 'center' },
			},
		],
		[navigate, pathname]
	);

	return (
		<>
			<Notification notification={notification} />
			<CustomTable
				wrapperClassName='p-4'
				pages={pages}
				showPaginationBottom={filterProps === "hari" || filterProps === "besok" ? false : true }
				tableKey={tableKey}
				isLoading={isLoading}
				data={data}
        dataPerPage={5}
				columns={tableColumns}
				onFetchData={({ page, filtered, sorted, pageSize }) => {
					fetchData(page, filtered, sorted, pageSize);
				}}
				showTableHeader={true}
			/>
		</>
	);
};

export default TableNegotiation;
