import Datepicker from 'react-tailwindcss-datepicker';
import TextInput from '@/components/form/TextInput';

export default function DatepickerInput({
	label,
	error,
	disabled,
	labelClass,
	name,
	value,
	parentClass = '',
	asSingle = true,
	useRange = false,
	displayFormat = 'DD-MM-YYYY',
	className = '',
	onChange,
	...otherProps
}) {
	return (
		<TextInput
			label={label}
			labelClass={labelClass}
			className={parentClass}
			error={error}
		>
			<Datepicker
				{...otherProps}
				disabled={disabled}
				inputId={name}
				inputName={name}
				containerClassName={`relative mt-1 ${className}`}
				inputClassName={`w-full border border-solid focus:outline-teal-500 rounded-lg duration-300 px-3 py-[10px] ${
					error ? 'border-red-500' : ''
				}`}
				asSingle={asSingle}
				useRange={useRange}
				displayFormat={displayFormat}
				value={value}
				onChange={onChange}
			/>
		</TextInput>
	);
}
