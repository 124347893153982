import Select from 'react-select';
import TextInput from '@/components/form/TextInput';

const SelectInput = ({
	label,
	error,
	value,
	isMulti = false,
	isSearchable = false,
	isClearable = false,
	disabled,
	placeholder,
	options,
	labelClass,
	className = '',
	parentClass = '',
	styles,
	onChange,
}) => {
	return (
		<TextInput
			label={label}
			error={error}
			labelClass={labelClass}
			className={parentClass}
		>
			<Select
				styles={{
					// ...styles,
					placeholder: (base, state) => ({
						...base,
						color: '#9FA6B2',
					}),
					option: (base, state) => ({
						...base,
						fontSize: '14px',
						color: state.isFocused ? '#000000' : state.isSelected ? '#ffffff' : base.color,
						backgroundColor: state.isSelected ? '#3AB8A6' : '#FFFF',
						'&:hover': {
							backgroundColor: state.isSelected ? '#3AB8A6' : '#3AB8A619',
							color: state.isSelected ? '#ffffff' : '#000000',
						},
						transition: '0.2s all ease-in-out',
					}),
					menu: (base, props) => ({
						...base,
						zIndex: 5,
					}),
					singleValue: (base, props) => ({
						...base,
						color: 'black',
					}),
					multiValue: (base, props) => ({
						...base,
						color: 'black',
						marginTop: '8px',
					}),
					control: (base, state) => ({
						...base,
						backgroundColor: disabled ? '#FAFAFA' : base.backgroundColor,
						borderColor: error ? 'red' : state.isFocused ? 'hsl(0, 0%, 50%)' : base.borderColor,
						boxShadow: 'none',
						borderRadius: '.5rem',
						height: '42px',
						paddingLeft: 3,
						paddingRight: 3,
						fontSize: 16,
						'&:hover': {
							borderColor: error ? 'red' : state.isFocused ? 'hsl(0, 0%, 50%)' : base.borderColor,
						},
					}),
					menuPortal: (base, props) => ({
						...base,
						zIndex: '60',
					}),
					valueContainer: (base, state) => ({
						...base,
						paddingTop: '0',
						paddingBottom: '2px',
						// top: '-4px',
					}),
					indicatorsContainer: (base, props) => ({
						...base,
						height: '100%',
					}),
					dropdownIndicator: (base, props) => ({
						...base,
						paddingLeft: '0',
					}),
					clearIndicator: (base, props) => ({
						...base,
						paddingLeft: '0',
						paddingRight: '0',
					}),
					...styles,
				}}
				components={{
					// DropdownIndicator:() => null
					IndicatorSeparator: () => null,
				}}
				value={value}
				onChange={onChange}
				options={options}
				isMulti={isMulti}
				isDisabled={disabled}
				isClearable={isClearable}
				isSearchable={isSearchable}
				placeholder={placeholder ? placeholder : 'Select...'}
				className={className}
			/>
		</TextInput>
	);
};

export default SelectInput;
