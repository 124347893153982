import React from 'react'
import TableBooking from '@/components/booking/TableBooking'

const BookingPage = () => {
  return (
    // <div className='bg-white h-full flex items-center justify-center flex-col rounded-lg p-10'>
    //   <img 
    //     alt="under construction"
    //     src="/images/under-construction.jpeg"
    //   />
    //   <h1 className='font-bold text-4xl mt-10'>This feature is under construction...</h1>
    // </div>
    <div className='bg-gray-100'>
      <div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Daftar Booking</h1>
			</div>

      <TableBooking />
    </div>
  )
}

export default BookingPage