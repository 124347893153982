import TableNegotiation from '@/components/negotiation/TableNegotiation';
import React, { useState } from 'react'

const FilterButton = ({ label, active, onClick }) => {
  return (
    <button onClick={onClick} className={`text-center w-full py-3 rounded-lg ${active ? "bg-orange-600 text-white" : "bg-white hover:bg-orange-50 hover:text-black"}`}>
      <h1 className='text-xl font-bold'>{label}</h1>
    </button>
  )
}

const filterOptions = [
  {value: "hari", label: "Hari ini"},
  {value: "besok", label: "Besok"},
  {value: "minggu", label: "Minggu ini"},
  {value: "bulan", label: "Bulan ini"},
];

const NegotiationBooking = () => {
  const [activeFilter, setActiveFilter] = useState("hari");

  const onChangeFilter = (value) => {
    setActiveFilter(value);
  };

  return (
    <div className='bg-gray-100'>
      <div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Daftar Jadwal Negosiasi</h1>
			</div>
      <div className='grid md:grid-cols-4 gap-8 mt-8'>
        {filterOptions.map((item, idx) => {
          return (
            <FilterButton 
              key={`filter-button-${idx}`}
              value={item.value}
              label={item.label}
              onClick={() => onChangeFilter(item.value)}
              active={activeFilter === item.value}
            />
          )
        })}
      </div>

      <TableNegotiation filterProps={activeFilter} />
    </div>
  )
}

export default NegotiationBooking