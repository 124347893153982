import Notification from "@/components/common/Notification";
import { API_URL } from "@/redux-client/api";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiEdit2Line } from "react-icons/ri";

const FilterButton = ({ label, active, onClick }) => {
  return (
    <button
      className={`text-center w-full py-3 rounded-lg ${
        active
          ? "bg-orange-600 text-white"
          : "bg-white hover:bg-orange-50 hover:text-black"
      }`}
      onClick={onClick}
    >
      <h2 className="font-semibold text-xl">{label}</h2>
    </button>
  );
};

const PackagePage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const [data, setData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [activeData, setActiveData] = useState(null);

  const fetchPackages = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${API_URL}/package-admin`);

      if (response.data.status === 200) {
        const { data } = response.data.message;

        setData(data);
        setActiveFilter(data[0]._id);
        setIsLoading(false);
      } else {
        const { message } = response.data;
        setNotification({
          title: message,
          text: "Please try again later.",
          isError: true,
        });
      }
    } catch (error) {
      console.log(error);
      setNotification({
        title: "Network error!",
        text: "Please try again later.",
        isError: true,
      });
    }
  }, []);

  useEffect(() => {
    fetchPackages();
    return () => {};
  }, [fetchPackages]);

  useEffect(() => {
    if (data) {
      const activePackages = data.find((item) => item._id === activeFilter);
      setActiveData(activePackages);
    }

    return () => {};
  }, [data, activeFilter]);

  return (
    <div className="bg-gray-100">
      <div className="bg-white text-center w-full py-5 rounded-lg">
        <h1 className="text-2xl font-bold">Daftar Paket</h1>
      </div>
      <Notification notification={notification} />

      <div className="mt-10 flex gap-4">
        {data.length > 0
          ? data.map((item, idx) => (
              <FilterButton
                key={`event-package-${idx}`}
                label={item.name}
                active={activeFilter === item._id}
                onClick={() => setActiveFilter(item._id)}
              />
            ))
          : null}
      </div>

      <div className="mt-10 flex gap-4 bg-white rounded-lg p-5">
        {activeData && activeData.packages.length > 0 ? (
          activeData.packages.map((item) => {
            return (
              <div key={item._id} className="p-5 w-1/4 rounded-lg border border-1 border-gray-200">
                <div className="text-xl py-3 flex items-center justify-center min-h-[200px]">
                  <label className="font-semibold cursor-pointer">
                    {item.name}
                  </label>
                </div>

                <button
                  className="bg-orange-500 text-white w-full rounded-lg py-3 cursor-pointer hover:scale-105 font-semibold flex items-center gap-2 justify-center"
                  onClick={() => {
                    navigate(`${pathname}/details`, {
                      state: {
                        package_id: item._id,
                      },
                    });
                  }}
                >
                  <RiEdit2Line size={16} />
                  Edit
                </button>
              </div>
            );
          })
        ) : (
          <div className="flex w-full justify-center items-center min-h-[200px]">
            Paket tidak ditemukan
          </div>
        )}
      </div>
    </div>
  );
};

export default PackagePage;
