import React from 'react'

const DataTextDisplay = ({ label, value }) => {
  return (
    <div>
      <label className="text-md">{label}</label>
      <p className="text-lg mt-1 font-bold">{value}</p>
    </div>
  );
};

export default DataTextDisplay