import React, { useCallback, useEffect, useMemo, useState } from "react";
import { nopeResolver } from "@hookform/resolvers/nope";
import Nope from "nope-validator";
import axios from "axios";
import moment from "moment";
import { HiOutlineSave } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { API_URL } from "@/redux-client/api";
import { currencyInputSeparator, rupiahFormat } from "@/helper";
import TextInput from "@/components/form/TextInput";
import DataTextDisplay from "@/components/common/DataTextDisplay";
import { isEmpty } from "validate.js";
import Notification from "../common/Notification";
import Loading from "../common/Loading";
import { useNavigate } from "react-router-dom";

const CategoryNameDisplay = ({ title }) => {
  return (
    <h4 className="font-semibold text-lg bg-gray-200 px-4 py-2 rounded-lg">
      {title}
    </h4>
  );
};

const NegotiationConfirmationForm = ({ data }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [notification, setNotification] = useState(null);
  const [payloadErrors, setPayloadErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [getMenuLoading, setGetMenuLoading] = useState(false);

  const [menuTypeOptions, setMenuTypeOptions] = useState([]);
  const [menuTextList, setMenuTextList] = useState([]);
  const [additionalPackages, setAdditionalPackages] = useState([]);
  const [additionalPackagesErrors, setAdditionalPackagesErrors] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);

  const ConfirmationSchema = useMemo(() => {
    const editDynamicSchema = {};

    editDynamicSchema["negotiation_time"] = Nope.string().required(
      "Masukkan waktu negosiasi"
    );
    editDynamicSchema["negotiation_date"] = Nope.string().required(
      "Masukkan tanggal negosiasi"
    );

    return Nope.object().shape(editDynamicSchema);
  }, []);

  const {
    register,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: nopeResolver(ConfirmationSchema),
    defaultValues: {
      _id: null,
      admin_id: null,
      order_items: null,
      estimated_total_price: 0,
      actual_total_price: 0,
      extra_order_fee: 0,
      main_package: {
        total_pax: 0,
        subtotal_price: 0,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { order_items, estimated_total_price } = data;
      setValue("main_package", order_items.main_package);
      setValue("estimated_total_price", estimated_total_price);
      setAdditionalPackages(order_items.additional_packages);
    }
    return () => {};
  }, [data, setValue]);

  const getMenuOptions = useCallback(async () => {
    try {
      setGetMenuLoading(true);
      const response = await axios.post(`${API_URL}/package/menu-options`, {
        package_name: data.order_items.main_package.package_name,
      });
      if (response.status === 200) {
        const { data } = response.data.message;
        if(data.menus.length > 0) {
          const selectedMenusTemplate = data.menus.map((item) => {
            return {
              menuType: item.menuType.name,
              menuTypeVariations: "",
            };
          });
          setSelectedMenus(selectedMenusTemplate);
          setMenuTypeOptions(data.menus);
        } else {
          const menusTextListTemplate = data.menus_text_list.map(item => {
            if(item.includes("/")) {
              return {
                menuType: item,
                menuTypeVariations: "",
              }
            } else {
              return {
                menuType: item,
                menuTypeVariations: item
              };
            };
          });

          setMenuTextList(menusTextListTemplate)
        }
        setGetMenuLoading(false);
      } else {
        setGetMenuLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.order_items) {
      getMenuOptions();
    }
    return () => {};
  }, [getMenuOptions, data]);

  const onChangeTotalPax = (value) => {
    const mainPackageSubtotalPrice = value * data.order_items.main_package.price_per_pax;
    setValue("main_package.total_pax", value);
    setValue(
      "main_package.subtotal_price",
      mainPackageSubtotalPrice
    );

    const newAdditionalPackages = additionalPackages.map(item => {
      return {
        ...item,
        total_pax: value,
        subtotal_price: item.price_per_pax*value
      }
    });

    setAdditionalPackages(newAdditionalPackages);

    const additionalPackagesSubtotalSum = newAdditionalPackages.reduce((accumulator, object) => {
      return accumulator + object.subtotal_price;
    }, 0);

    setValue("estimated_total_price", mainPackageSubtotalPrice+additionalPackagesSubtotalSum)
  };

  const isValid = (payload) => {
    const {
      actual_total_price,
      order_items,
    } = payload;

    const { main_package } = order_items;

    let errorsPayload = {}

    if(actual_total_price === 0) {
      setError('actual_total_price', { type: 'custom', message: 'Masukkan Harga Deal' });
      errorsPayload["actual_total_price"] = "Masukkan harga deal"
    };

    if(main_package.selected_menus.length === 0) {
      errorsPayload["main_package_menus"] = "Form pilih menu wajib diisi."
    } else {
      let menusError = false;
      main_package.selected_menus.map(item => {
        if(isEmpty(item.menuTypeVariations)) {
          menusError = true;
          return false
        };

        return true;
      });

      if(menusError) errorsPayload["main_package_menus"] = "Form pilih menu wajib diisi."
    };


    setPayloadErrors(errorsPayload);
    return isEmpty(errorsPayload);
  };


  const onSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    clearErrors();
    setNotification(null);
    setPayloadErrors({});
    setAdditionalPackagesErrors([]);

    const dataPayload = {
      _id: data._id,
      booking_code: data.booking_code,
      admin_id: user._id,
      order_items: {
        main_package: {
          ...getValues("main_package"),
          selected_menus: menuTypeOptions.length > 0 ? selectedMenus : menuTextList
        },
        additional_packages: additionalPackages
      },
      estimated_total_price: Number(getValues("estimated_total_price")),
      actual_total_price: getValues("actual_total_price") ? Number(getValues("actual_total_price").split(".").join("")) : 0,
      extra_order_fee: Number(getValues("extra_order_fee"))
    }

    if(isValid(dataPayload)) {
      try {
        const response = await axios.post(`${API_URL}/negotiation/confirmation`, dataPayload);

        if (response.status === 200) {
          setNotification({
            isError: false,
            title: "Konfirmasi Berhasil",
            text: "Pesanan telah berhasil dikonfirmasi dan email konfirmasi pesanan telah dikirim."
          });

          setTimeout(() => {
            setLoading(false);
            setNotification({});
            navigate('/bookings');
          }, 5000)
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setNotification({
          isError: true,
          title: "Oooppss...",
          text: "Terjadi kesalahan pada koneksi. Pastikan koneksi internet Anda baik."
        })
      }

    } else {
      setNotification({
        isError: true,
        title: "Data tidak lengkap",
        text: "Pastikan Anda sudah melengkapi form konfirmasi pesanan diatas."
      })
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="text-left">
        <CategoryNameDisplay title="Informasi Acara" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-3">
          <DataTextDisplay
            label={"Acara"}
            value={data?.event_data.event_type}
          />
          <DataTextDisplay
            label={"Tanggal Acara"}
            value={
              data && data.event_data
                ? moment(data.event_data.event_date, "DD/MM/YYYY").format(
                    "DD MMMM YYYY"
                  )
                : "-"
            }
          />
          <DataTextDisplay
            label={"Lokasi Acara"}
            value={data?.event_data.event_location}
          />
          <DataTextDisplay
            label={"Keterangan Tambahan"}
            value={data?.event_data.event_note}
          />
        </div>
      </div>
      <div className="text-left mt-8">
        <h4 className="font-semibold text-lg">Informasi Pemesan</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-3">
          <DataTextDisplay label={"Nama"} value={data?.customer_id.name} />
          <DataTextDisplay label={"Email"} value={data?.customer_id.email} />
          <DataTextDisplay label={"No. HP"} value={data?.customer_id.phone} />
          <DataTextDisplay label={"Alamat"} value={data?.customer_id.address} />
        </div>
      </div>
      <div className="text-left mt-3">
        <CategoryNameDisplay title="Informasi Paket Pilihan" />
      </div>
      <div className="text-left mt-5">
        <h4 className="font-semibold text-lg">Paket Utama</h4>
      </div>
      <form name="paket_utama" className="mt-5">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
          <TextInput label="Nama Paket" labelClass="text-left">
            <input
              type="text"
              value={
                `Paket ${data?.order_items.main_package.package_name}` || ""
              }
              disabled={true}
              readOnly
            />
          </TextInput>
          <TextInput
            label="Total pax"
            labelClass="text-left"
            error={errors?.main_package_total_pax}
          >
            <input
              // type='number'
              {...register("main_package.total_pax")}
              className="cursor-auto"
              value={watch("main_package.total_pax")}
              onChange={(e) => onChangeTotalPax(e.target.value)}
              disabled={loading}
              aria-invalid={!!errors?.main_package_total_pax}
            />
          </TextInput>
          <TextInput
            label="Harga per pax"
            labelClass="text-left"
            error={errors?.main_package_price_per_pax}
          >
            <input
              // type='number'
              {...register("main_package_price_per_pax")}
              className="cursor-auto"
              value={rupiahFormat(data?.order_items.main_package.price_per_pax)}
              disabled={true}
              readOnly
              aria-invalid={!!errors?.main_package_price_per_pax}
            />
          </TextInput>
          <TextInput
            label="Subtotal"
            labelClass="text-left"
            error={errors?.main_package_subtotal_price}
          >
            <input
              // type='number'
              {...register("main_package.subtotal_price")}
              className="cursor-auto"
              value={rupiahFormat(watch("main_package.subtotal_price"))}
              disabled={true}
              readOnly
              aria-invalid={!!errors?.main_package_subtotal_price}
            />
          </TextInput>
        </div>
      </form>
      <div className="text-left mt-8">
        <h4 className="font-semibold text-lg">Pilih Menu Paket Utama</h4>
        <div className="mt-5">
          {menuTypeOptions.length > 0 ? menuTypeOptions.map((item, idx) => {
            return (
              <div
                key={`menu-types-options-${idx}`}
                className="grid grid-cols-3 mt-3 flex items-center"
              >
                <label>{item.menuType.name}</label>
                <TextInput className="col-span-2">
                  <input
                    type="text"
                    className="cursor-auto"
                    value={menuTypeOptions[idx].menuTypeVariations}
                    onChange={(e) => {
                      const newValue = selectedMenus.map((item, sidx) => {
                        if(idx === sidx) {
                          return {
                            ...item,
                            menuTypeVariations: e.target.value
                          }
                        };
                        return item
                      });

                      setSelectedMenus(newValue);
                    }}
                    disabled={getMenuLoading}
                    // aria-invalid={!!errors?.actual_total_price}
                  />
                </TextInput>
              </div>
            );
          }) : menuTextList.length > 0 ? 
            menuTextList.map((item, idx) => {
              return (
                <div
                key={`menu-list-${idx}`}
                className="grid grid-cols-3 mt-3 flex items-center"
              >
                <label>{item.menuType}</label>
                <TextInput className="col-span-2">
                  <input
                    type="text"
                    className="cursor-auto"
                    value={item.menuTypeVariations}
                    onChange={(e) => {
                      const newValue = menuTextList.map((item, sidx) => {
                        if(idx === sidx) {
                          return {
                            menuType: item.menuType,
                            menuTypeVariations: e.target.value
                          }
                        } else {
                          return item;
                        }
                      });
                      
                      setMenuTextList(newValue);
                    }}
                    disabled={!item.menuType.includes("/")}
                    // aria-invalid={!!errors?.actual_total_price}
                  />
                </TextInput>
              </div>
                // <p key={`menu-${idx}`} className="mt-2">. {item}</p>
              )
            })
          : null }
        </div>
        { payloadErrors && payloadErrors.main_package_menus && 
          <small className="block text-center mt-5 bg-red-100 text-red-600 w-full px-3 py-2">
            Form Pilih Menu Wajib Diisi Lengkap
          </small>
        }
      </div>
      {additionalPackages.length > 0 ? (
        <div className="text-left mt-5">
          <h4 className="font-semibold text-lg">Paket Tambahan</h4>
          <form name="additional_package" className="mt-5">
            {additionalPackages.map((item, idx) => {
              return (
                <div
                  key={`additional_package_${idx}`}
                  className="grid grid-cols-1 md:grid-cols-4 gap-5 mt-5"
                >
                  <TextInput
                    label="Nama Paket"
                    labelClass="text-left"
                    error={additionalPackagesErrors[idx]?.package_name}
                  >
                    <input
                      // type='number'
                      value={item.package_name}
                      disabled={true}
                      readOnly
                      aria-invalid={!!additionalPackagesErrors[idx]?.package_name}
                    />
                  </TextInput>
                  <TextInput
                    label="Total pax"
                    labelClass="text-left"
                    error={additionalPackagesErrors[idx]?.total_pax}
                  >
                    <input
                      className="cursor-auto"
                      value={item.total_pax}
                      disabled={true}
                      readOnly
                      aria-invalid={!!additionalPackagesErrors[idx]?.total_pax}
                    />
                  </TextInput>
                  <TextInput
                    label="Harga per pax"
                    labelClass="text-left"
                    error={additionalPackagesErrors[idx]?.price_per_pax}
                  >
                    <input
                      className="cursor-auto"
                      value={rupiahFormat(
                        item.price_per_pax
                      )}
                      disabled={true}
                      readOnly
                      aria-invalid={!!additionalPackagesErrors[idx]?.price_per_pax}
                    />
                  </TextInput>
                  <TextInput
                    label="Subtotal"
                    labelClass="text-left"
                    error={additionalPackagesErrors[idx]?.subtotal_price}
                  >
                    <input
                      className="cursor-auto"
                      value={rupiahFormat(item.subtotal_price)}
                      disabled={true}
                      readOnly
                      aria-invalid={!!additionalPackagesErrors[idx]?.subtotal_price}
                    />
                  </TextInput>
                </div>
              );
            })}
          </form>
        </div>
      ) : null}
      <div className="text-left mt-8">
        <CategoryNameDisplay title="Informasi Total Biaya" />
      </div>
      <form name="total_biaya" className="mt-5">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
          <TextInput
            label="Total Harga Estimasi"
            labelClass="text-left"
            error={errors?.estimated_total_price}
          >
            <input
              // type='number'
              {...register("estimated_total_price")}
              value={rupiahFormat(watch("estimated_total_price"))}
              disabled={true}
              readOnly
              aria-invalid={!!errors?.estimated_total_price}
            />
          </TextInput>
          <TextInput
            label="Harga Deal"
            labelClass="text-left"
            error={errors?.actual_total_price}
          >
            <input
              // type='number'
              {...register("actual_total_price")}
              value={watch("actual_total_price")}
              onChange={(e) => {
                setValue(
                  "actual_total_price",
                  currencyInputSeparator(e.target.value)
                );
              }}
              disabled={loading}
              aria-invalid={!!errors?.actual_total_price}
            />
          </TextInput>
          <TextInput
            label="Biaya Tambahan"
            labelClass="text-left"
            error={errors?.extra_order_fee}
          >
            <input
              // type='number'
              {...register("extra_order_fee")}
              value={watch("extra_order_fee")}
              onChange={(e) => {
                setValue(
                  "extra_order_fee",
                  currencyInputSeparator(e.target.value)
                );
              }}
              disabled={loading}
              aria-invalid={!!errors?.extra_order_fee}
            />
          </TextInput>
        </div>
      </form>
      <div>
        <Notification notification={notification} className="text-left" />
      </div>
      { loading ? 
        <div className="mt-10">
          <Loading type="beat-loader" />
        </div>
        : <button
          type="submit"
          onClick={onSubmit}
          className="bg-orange-600 text-white rounded-xl font-semibold pl-5 pr-6 py-4 flex items-center mt-10 mb-0 w-full justify-center"
        >
          <HiOutlineSave size={17} className="mr-1" />
          <label>Simpan</label>
        </button>
      }
    </div>
  );
};

export default NegotiationConfirmationForm;
