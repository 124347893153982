import React, { useCallback, useEffect, useState } from "react";
import Loading from "../common/Loading";
import axios from "axios";
import { API_URL } from "@/redux-client/api";
import Notification from "../common/Notification";

const EditMenuForm = ({ packageId, data, onCloseModal }) => {
  const [newSelectedMenuVariations, setNewSelectedMenuVariations] = useState(data.options);
  const [menuVariationOptions, setMenuVariationOptions] = useState([]);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [notification, setNotification] = useState(null);

  const fetchMenuVariations = useCallback(async () => {
    setIsLoadingFetch(true);

    try {
      const response = await axios.post(
        `${API_URL}/package-admin/menu-variations`,
        {
          menuType_id: data.menuType._id,
        }
      );

      if (response.status === 200) {
        const { data } = response.data;
        setMenuVariationOptions(data);
        setSearchResult(data);
        setIsLoadingFetch(false);
      }
    } catch (error) {
      setIsLoadingFetch(false);
      setMenuVariationOptions([]);
    }
  }, [data, setMenuVariationOptions]);

  useEffect(() => {
    setNotification(null);
    fetchMenuVariations();
  }, [fetchMenuVariations]);

  useEffect(() => {
    setNotification(null);
    if(menuVariationOptions.length > 0) {
      const searchResult = menuVariationOptions.filter(item => item.name.toLowerCase().includes(searchInput));
      setSearchResult(searchResult);
    };
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const onSelectAll = () => {
    setNotification(null);
    if(newSelectedMenuVariations.length !== menuVariationOptions.length) {
      setNewSelectedMenuVariations(menuVariationOptions);
    } else {
      setNewSelectedMenuVariations([]);
    };
  };

  const onSelectMenuVariation = (selectedMenu) => {
    setNotification(null);
    const isChecked = newSelectedMenuVariations.some(item => item._id === selectedMenu._id);
    let newValue = newSelectedMenuVariations;

    if(isChecked) {
      newValue = newSelectedMenuVariations.filter(item => item._id !== selectedMenu._id);
    } else {
      newValue = [...newValue, selectedMenu];
    };

    setNewSelectedMenuVariations(newValue);
  };

  const handleSubmit = async() => {
    if(newSelectedMenuVariations.length === 0) {
      setNotification({
        isError: true,
        title: 'Error',
        text: "Harus pilih minimal 1 menu",
      });
    } else {
      const payload = {
        package_id: packageId,
        menus: {
          menuType: data.menuType._id,
          options: newSelectedMenuVariations.map(item => item._id)
        }
      };

      setIsLoadingSubmit(true);

      try {
        const response = await axios.post(`${API_URL}/package-admin/update`, payload);

        if (response.status === 200) {
          const { message } = response.data;
          setIsLoadingSubmit(false);
          setNotification({
            isError: true,
            title: 'Success',
            text: message,
          });
          onCloseModal();
        } else {
          setIsLoadingSubmit(false);
          setNotification({
            isError: true,
            title: 'Error',
            text: "Internal server error.",
          });
        }
        
      } catch (error) {
        if (error?.response?.data) {
          const { message } = error.response.data;
          setNotification({
            isError: true,
            title: 'Error',
            text: message,
          });
        }
        
        setTimeout(() => {
          setNotification(null);
          setIsLoadingSubmit(false);
        }, 2000);
      }
    }
  }

  return (
    <div>
      {isLoadingFetch ? (
        <div className="py-10">
          <Loading type="beat-loader" loading={isLoadingFetch} size={24} />
        </div>
      ) : (
        <div>
          <div className="flex justify-start gap-10">
              <input
                disabled={isLoadingSubmit | isLoadingFetch} 
                className="border border-gray-200 px-4 py-2 rounded-lg flex-1"
                type="text"
                placeholder="Cari nama menu..."
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.toLowerCase());
                }}
              />
              <label className="flex items-center gap-2">
                <input
                  disabled={isLoadingSubmit | isLoadingFetch} 
                  type="checkbox"
                  className="accent-orange-600"
                  checked={newSelectedMenuVariations.length === menuVariationOptions.length}
                  onChange={() => onSelectAll()}
                />
                Pilih Semua
              </label>
          </div>
          <hr className="my-5" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {searchResult.length > 0
              ? searchResult.map((item) => {
                  return (
                    <label key={item._id} className="flex items-center gap-2">
                      <input
                        disabled={isLoadingSubmit | isLoadingFetch}
                        type="checkbox"
                        className="accent-orange-600"
                        checked={newSelectedMenuVariations.some(el => el._id === item._id)}
                        onChange={() => onSelectMenuVariation(item)}
                      />
                      {item.name}
                    </label>
                  );
                })
              : null}
          </div>
          <hr className="my-5" />
          <Notification notification={notification} />
          <div className="flex justify-end mt-3">
            <button 
              className="bg-orange-600 text-white rounded-lg px-4 py-2"
              onClick={handleSubmit}
            >
              Simpan
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditMenuForm;
