import { EditFormAdmin } from '@/components/admin/FormAdmin';
import Button from '@/components/common/Button';
import Loading from '@/components/common/Loading';
import Notification from '@/components/common/Notification';
import ChangePasswordForm from '@/components/settings/ChangePasswordForm';
import { API_URL } from '@/redux-client/api';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { HiPencilSquare } from 'react-icons/hi2';
import { useSelector } from 'react-redux';

const Settings = () => {
	const { user } = useSelector((state) => state.auth);

	const [pageLoading, setPageLoading] = useState(false);
	const [pageNotification, setPageNotification] = useState(null);
	const [dataAdmin, setDataAdmin] = useState(null);
	const [disableEditForm, setDisableEditForm] = useState(true);


	const fetchSingleAdmin = useCallback(async () => {
		try {
			setPageLoading(true);
			const response = await axios.get(`${API_URL}/admin/${user._id}`);
			if (response.status === 200) {
				const { data } = response.data;
				setDataAdmin(data);
				setPageLoading(false);
			}
		} catch (error) {
			if (error?.response?.data) {
				const { message } = error.response.data;
				setPageNotification({
					isError: true,
					title: 'Gagal menyimpan data. Mohon coba beberapa saat lagi.',
					text: message,
				});
			}
			setTimeout(() => {
				setPageNotification(null);
			}, 2000);
			setPageLoading(false);
		}
	}, [user]);

	useEffect(() => {
		fetchSingleAdmin();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<div>
			<div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Pengaturan Akun</h1>
			</div>
			<div className='flex flex-wrap items-center justify-between mt-10 mb-6'>
				{disableEditForm ? (
					<Button
						parentClass='ml-auto'
						onClick={() => setDisableEditForm(false)}
					>
						<HiPencilSquare className='mr-2' /> Edit
					</Button>
				) : null}
			</div>
			<Notification notification={pageNotification} />
			<div className='bg-white p-5 mt-4 rounded-xl'>
				{pageLoading ? (
					<div className='py-10'>
						<Loading
							type='beat-loader'
							loading={pageLoading}
							size={24}
						/>
					</div>
				) : dataAdmin ? (
					<EditFormAdmin
						data={dataAdmin}
						disabled={disableEditForm}
						className='sm:grid-cols-3'
						closeModalHandler={() => {
							setDisableEditForm(true);
						}}
					/>
				) : null}
			</div>

			{/* Pengaturan Password */}
			<div className='bg-white text-center w-full py-5 rounded-lg mt-10'>
				<h2 className='text-2xl font-bold'>Pengaturan Password</h2>
			</div>
			<div className='bg-white p-5 mt-4 rounded-xl'>
				<ChangePasswordForm userId={user._id} />
			</div>
		</div>
	);
};

export default Settings;
