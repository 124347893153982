import { useLocation } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { useState } from 'react';

const MainLayout = ({ children }) => {
	const { pathname } = useLocation();

	const [isOpenSidebar, setIsOpenSidebar] = useState(false);

	if (pathname === '/login') return children;

	return (
		<div className='flex flex-col w-full h-full bg-gray-100'>
			<Header
				isOpenSidebar={isOpenSidebar}
				setIsOpenSidebar={setIsOpenSidebar}
			/>
			<div className='flex flex-grow relative min-h-full'>
				<div className={`${isOpenSidebar ? 'block absolute h-full' : 'hidden'} lg:block z-20`}>
					<Sidebar />
				</div>
				<div className='px-5 py-5 flex-grow overflow-x-auto min-h-[88.6vh]'>{children}</div>
			</div>
		</div>
	);
};

export default MainLayout;