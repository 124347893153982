import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

// export type ButtonTypeProp = 'primary' | 'primary-outline' | 'secondary' | 'info' | 'error' | 'error-outline';
// export type ButtonSizeProp = 'lg' | 'sm' | 'xl' | 'circle' | 'circle-sm' | 'circle-xl';

const Button = ({
	buttonType = 'primary',
	buttonSize = 'lg',
	rounded = true,
	disabled = false,
	href,
	linkState,
	parentClass = '',
	className = '',
	children,
	type = 'button',
	handleHrefActive,
	onClick,
}) => {
	const buttonClass = useMemo(() => {
		let styleClass = 'inline-flex items-center font-bold border border-current transition ease-in-out duration-300 hover:scale-[102%]';

		if(rounded) {
			styleClass += ' rounded-full';
		} else {
			styleClass += ' rounded-lg';
		}

		if (buttonType === 'primary') {
			styleClass += ' border-orange-600 bg-orange-600 text-white hover:bg-orange-600 hover:border-orange-600';
		} else if(buttonType === 'primary-outline') {
			styleClass += ' border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white';
		} else if (buttonType === 'secondary') {
			styleClass += ' border-orange-100 bg-orange-100 text-orange-600 hover:border-orange-600 hover:bg-orange-600 hover:text-white';
		} else if (buttonType === 'info') {
			styleClass += ' border-gray-200 bg-gray-200 text-black hover:text-orange-600 hover:border-orange-100 hover:bg-orange-100';
		} else if (buttonType === 'error') {
			styleClass += ' border-red-500 bg-red-500 text-white hover:border-red-600 hover:bg-red-600';
		} else if(buttonType === 'error-outline') {
			styleClass += ' border-red-500 text-red-500 hover:bg-red-500 hover:text-white';
		}

		if (buttonSize === 'lg') {
			styleClass += ' text-base px-5 pt-2 pb-[10px]';
		} else if (buttonSize === 'sm') {
			styleClass += ' text-sm px-3 py-1 pb-2';
		} else if (buttonSize === 'xl') {
			styleClass += ' text-lg px-6 py-3 pb-4';
		} else if (buttonSize === 'circle') {
			styleClass += ' text-base justify-center w-10 h-10';
		} else if (buttonSize === 'circle-sm') {
			styleClass += ' text-sm justify-center w-8 h-8';
		} else if (buttonSize === 'circle-xl') {
			styleClass += ' text-base justify-center w-12 h-12';
		}

		return `${className} ${styleClass}`;
	}, [buttonType, buttonSize, rounded, className]);

	return (
		<div className={`inline-flex items-center justify-center ${parentClass}`}>
			{href ? (
				<NavLink
					to={href}
					state={linkState}
					className={({ isActive }) => {
						if (typeof handleHrefActive === 'function') {
							handleHrefActive(isActive);
						}

						return buttonClass;
					}}
					end={true}
					caseSensitive={true}
					onClick={onClick}
				>
					{children}
				</NavLink>
			) : (
				<button
					type={type}
					disabled={disabled}
					className={buttonClass}
					onClick={onClick}
				>
					{children}
				</button>
			)}
		</div>
	);
};

export default Button;
