import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isAuthenticated: false,
	token: null,
	user: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			const { token, user } = action.payload;
			state.isAuthenticated = action.payload !== null;
			state.token = token;
			state.user = user;
		},
		userLogout: (state) => {
			state.isAuthenticated = false;
			state.token = null;
			state.user = null;
		},
	},
});

export const { setCredentials, userLogout } = authSlice.actions;

export default authSlice.reducer;
