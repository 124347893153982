import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "dayjs/locale/id";
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiClock,
} from "react-icons/fi";
import { isEmpty, isFunction } from "validate.js";
import { useState } from "react";
import { Controller } from "react-hook-form";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault("Asia/Jakarta");
dayjs.locale("id");

const TimePickerInput = ({
  label,
  labelClass,
  parentClass,
  errors,
  name = "",
  control,
  defaultValue = null,
  format = "HH:mm",
  helperText = "",
  disabled = false,
  className = "",
  onChange,
  onClose,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);

  if (isEmpty(name) || isEmpty(control)) {
    return null;
  }

  return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
					<div className="flex flex-col">
						<label className={`text-sm font-semibold ml-1 mb-1 ${labelClass}`}>{label}</label>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="id">
							<TimePicker
								{...field}
								{...otherProps}
								open={open}
								format={format}
								ampm={false}
								disabled={disabled}
								slotProps={{
									textField: {
										fullWidth: true,
										error: !isEmpty(errors[name]),
										helperText: errors[name] ? errors[name].message : helperText,
										onClick: () => {
											if (!disabled) {
												setOpen(true);
											}
										},
									},
								}}
								slots={{
									leftArrowIcon: () => <FiChevronLeft />,
									rightArrowIcon: () => <FiChevronRight />,
									switchViewIcon: () => <FiChevronDown />,
									openPickerIcon: () => <FiClock />,
								}}
								dayOfWeekFormatter={(day) => day}
								className={`MuiDatepicker-root w-full ${className}`}
								onChange={(e) => {
									field.onChange(e);

									if (isFunction(onChange)) {
										onChange(e);
									}
								}}
								onClose={(e) => {
									setOpen(false);

									if (isFunction(onClose)) {
										onClose(e);
									}
								}}
							/>
						</LocalizationProvider>
					</div>
        )}
      />
  );
};

export default TimePickerInput;
