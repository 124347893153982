import { useCallback, useEffect, useRef } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { IoClose } from 'react-icons/io5';

// type Props = {
// 	title: string;
// 	children?: any;
// 	showCloseButton?: boolean;
// 	parentClass?: string;
// 	innerClass?: string;
// 	isOpen?: boolean;
// 	onClose?: () => void;
// };

const Modal = ({
	title,
	children,
	showCloseButton,
	parentClass = '',
	innerClass = '',
	isOpen = false,
	onClose,
}) => {
	const modalRef = useRef(null);

	useEffect(() => {
		if (isOpen && modalRef?.current) {
			disableBodyScroll(modalRef?.current);
		} else {
			clearAllBodyScrollLocks();
		}
	}, [isOpen, modalRef]);

	const handleClose = useCallback(() => {
		if (typeof onClose === 'function') {
			onClose();
		}

		clearAllBodyScrollLocks();
	}, [onClose]);

	if (!isOpen) {
		return null;
	}

	return (
		<div
			role='dialog'
			aria-modal='true'
			className={`fixed top-0 left-0 w-full h-full overflow-auto p-4 z-50 ${parentClass}`}
		>
			<div
				className='fixed inset-0 z-[1] bg-gray-500/75'
				onClick={handleClose}
			></div>
			<div
				ref={modalRef}
				className='w-full h-full flex items-stretch justify-center'
			>
				<div
					className={`relative z-[2] w-full md:w-[500px] m-auto p-6 rounded-xl bg-white shadow-xl ${innerClass}`}
				>
					<div className='flex items-center justify-between'>
						<h3
							className={`text-lg font-semibold leading-6 text-gray-900 m-0 ${
								showCloseButton ? 'w-[calc(100%-28px)] text-left' : 'w-full text-center'
							}`}
						>
							{title}
						</h3>
						{showCloseButton ? (
							<button
								className='flex justify-center items-center w-[28px] text-2xl text-red-500 hover:text-red-600'
								onClick={handleClose}
							>
								<IoClose />
							</button>
						) : null}
					</div>
					<div className='mt-6'>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;