import Button from '@/components/common/Button';
import Notification from '@/components/common/Notification';
import TextInput from '@/components/form/TextInput';
import { nopeResolver } from '@hookform/resolvers/nope';
import { API_URL } from '@/redux-client/api';
import axios from 'axios';
import Nope from 'nope-validator';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiOutlineSave } from 'react-icons/hi';
import { MoonLoader } from 'react-spinners';

const schemaChangePassword = Nope.object().shape({
	recentPassword: Nope.string().required('Masukkan password lama wajib!'),
	newPassword: Nope.string().required('Masukkan password baru wajib!'),
	confirmNewPassword: Nope.string()
		.oneOf([Nope.ref('newPassword')], 'Konfirmasi password tidak sesuai dengan password baru')
		.required('Masukkan konfirmasi password baru wajib!'),
});

const ChangePasswordForm = ({ userId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);

	const {
		register,
		handleSubmit,
		setValue,
		// setError,
		formState: { errors },
	} = useForm({
		defaultValues: {
			_id: userId,
			recentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
		},
		resolver: nopeResolver(schemaChangePassword),
	});

	const resetValues = useCallback(() => {
		setValue('recentPassword', '');
		setValue('newPassword', '');
		setValue('confirmNewPassword', '');
	}, [setValue]);

	const onSubmit = useCallback(async (data) => {
		try {
			setIsLoading(true);
			const payload = data;
			const response = await axios.post(`${API_URL}/auth-crm/change-password`, payload);
			if (response.status === 200) {
				const { message } = response.data;

				setNotification({
					isError: false,
					title: 'Sukses',
					text: message,
				});

				setIsLoading(false);

				setTimeout(() => {
					setNotification(null);
				}, 2000);
				resetValues();
			}
		} catch (error) {
			if (error?.response?.data) {
				const { message } = error.response.data;
				setNotification({
					isError: true,
					title: 'Gagal menyimpan data. Mohon coba beberapa saat lagi.',
					text: message,
				});
			}
			setTimeout(() => {
				setNotification(null);
			}, 2000);
			setIsLoading(false);
		}
	}, [resetValues, setIsLoading, setNotification]);

	return (
		<form className='pt-6'>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
				<TextInput
					label='Password lama'
					error={errors?.recentPassword}
				>
					<input
						type='password'
						{...register('recentPassword')}
						disabled={isLoading}
						aria-invalid={!!errors?.recentPassword}
					/>
				</TextInput>
				<TextInput
					label='Password baru'
					error={errors?.newPassword}
				>
					<input
						type='password'
						{...register('newPassword')}
						disabled={isLoading}
						aria-invalid={!!errors?.newPassword}
					/>
				</TextInput>
				<TextInput
					label='Konfirmasi password baru'
					error={errors?.confirmNewPassword}
				>
					<input
						type='password'
						{...register('confirmNewPassword')}
						disabled={isLoading}
						aria-invalid={!!errors?.confirmNewPassword}
					/>
				</TextInput>
			</div>
			{notification ? (
				<div className='mt-8'>
					<Notification notification={notification} />
				</div>
			) : null}
			<div className='flex justify-end mt-8'>
				{isLoading ? (
					<div className='px-4'>
						<MoonLoader
							cssOverride={{ display: 'block' }}
							className=''
							color='#2D9488'
							loading={isLoading}
							size={24}
						/>
					</div>
				) : (
					<Button
						type='submit'
						onClick={handleSubmit(onSubmit)}
					>
						<HiOutlineSave className='mr-2' />
						Simpan
					</Button>
				)}
			</div>
		</form>
	);
};

export default ChangePasswordForm;
