
import React from 'react'
import { IoChevronBack } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import BookingDetails from '@/components/booking/BookingDetails';

const BookingDetailsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className='bg-white text-center w-full py-5 rounded-lg relative'>
				<h1 className='text-2xl font-bold'>Detail Pesanan</h1>
        <button onClick={() => navigate('/bookings')} className='absolute left-[10px] top-[29%]'>
          <IoChevronBack size={30} />
        </button>
			</div>

      <div className='bg-white w-full p-5 mt-5 rounded-lg'>
				<BookingDetails booking_code={state?.booking_code} />
			</div>
    </div>
  )
}

export default BookingDetailsPage