import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { HiChevronLeft } from 'react-icons/hi';
import { HiPencilSquare } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';

import { API_URL } from '@/redux-client/api';
import Button from '@/components/common/Button';
import Loading from '@/components/common/Loading';
import Notification from '@/components/common/Notification';
import { EditFormAdmin } from '@/components/admin/FormAdmin';

const AdminDetailsPage = () => {
	const { state } = useLocation();
	const navigate = useNavigate();

	const [dataAdmin, setDataAdmin] = useState(null);
	const [pageLoading, setPageLoading] = useState(false);
	const [pageNotification, setPageNotification] = useState(null);
	const [disableEditForm, setDisableEditForm] = useState(true);

	const fetchSingleAdmin = useCallback(async () => {
		setPageLoading(true);

		try {
			const response = await axios.get(`${API_URL}/admin/${state._id}`);
			if (response.status === 200) {
				const { data } = response.data;
				setDataAdmin(data);
				setPageLoading(false);
			}
		} catch (error) {
			if (error?.response?.data) {
				const { message } = error.response.data;
				setPageNotification({
					isError: true,
					title: 'Error',
					text: message,
				});
			}

			setPageLoading(false);

			setTimeout(() => {
				setPageNotification(null);
			}, 2000);
		}
	}, [state]);

	useEffect(() => {
		fetchSingleAdmin();
	}, [fetchSingleAdmin, disableEditForm]);

	return (
		<div>
			<div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Admin Details</h1>
			</div>
			<div className='flex flex-wrap items-center justify-between mt-10 mb-6'>
				<button
					onClick={() => navigate('/admins')}
					className='flex items-center py-2 rounded-xl text-black opacity-80 hover:opacity-100 transition-all ease-in-out duration-300'
				>
					<HiChevronLeft size={20} />
					Kembali
				</button>
				{disableEditForm ? (
					<Button onClick={() => setDisableEditForm(false)}>
						<HiPencilSquare className='mr-2' /> Edit
					</Button>
				) : (
					<Button
						buttonType='error-outline'
						onClick={() => window.location.reload()}
					>
						Cancel Edit
					</Button>
				)}
			</div>
			<Notification notification={pageNotification} />
			<div className='bg-white p-10 rounded-xl'>
				{pageLoading ? (
					<div className='py-10'>
						<Loading
							type='beat-loader'
							loading={pageLoading}
							size={24}
						/>
					</div>
				) : (
					<>
						{dataAdmin ? (
							<EditFormAdmin
								data={dataAdmin}
								disabled={disableEditForm}
								className='sm:grid-cols-3'
								closeModalHandler={() => setDisableEditForm(true)}
							/>
						) : null}
					</>
				)}
			</div>
		</div>
	);
};

export default AdminDetailsPage;
