import React, { useState } from "react";
import { HiPencil } from "react-icons/hi";
import Modal from "../common/Modal";
import EditMenuForm from "./EditMenuForm";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const EditFormPackage = ({ packageId, menuList, fetchPackageDetails }) => {

  const [isOpenEditMenuModal, setIsOpenEditMenuModal] = useState(false);

  const [selectedMenuEdit, setSelectedMenuEdit] = useState(null);

  const onCloseModal = () => {
    fetchPackageDetails();
    setSelectedMenuEdit(null);
    setIsOpenEditMenuModal(false);
    clearAllBodyScrollLocks();
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {menuList.map((menu) => (
        <div
          key={menu._id}
          className="border border-gray-200 rounded-lg px-4 py-8"
        >
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">{menu.menuType.name}</h3>
            <button
              onClick={() => {
                setSelectedMenuEdit(menu);
                setIsOpenEditMenuModal(true);
              }}
              className="bg-orange-600 text-white pl-2 pr-3 py-1 rounded-lg flex items-center gap-2"
            >
              <HiPencil />
              Edit
            </button>
          </div>
          <hr className="my-4" />
          <ul className="list-disc pl-4">
            {menu.options.map((item) => (
              <li key={`${menu._id}-${item._id}`} className="">
                <label>{item.name}</label>
              </li>
            ))}
          </ul>
        </div>
      ))}

      {isOpenEditMenuModal && selectedMenuEdit && (
        <Modal
          title={`Edit Pilihan Menu ${selectedMenuEdit.menuType.name}`}
          isOpen={isOpenEditMenuModal}
          showCloseButton={true}
          onClose={onCloseModal}
          innerClass="lg:w-[1000px]"
        >
          <EditMenuForm packageId={packageId} data={selectedMenuEdit} onCloseModal={onCloseModal} />
        </Modal>
      )}
    </div>
  );
};

export default EditFormPackage;
