import { userLogout } from '@/redux-client/slices/authSlice';
import { useCallback, useMemo } from 'react';

import { IoIosLogOut } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Header = ({ isOpenSidebar, setIsOpenSidebar }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user, isAuthenticated } = useSelector((state) => state.auth);

	const onLogout = useCallback(() => {
		dispatch(userLogout());

		navigate('login');
	}, [dispatch, navigate]);

	const genericHamburgerLine = useMemo(
		() => `h-1 w-8 my-1 rounded-full bg-black transition ease transform duration-300`,
		[]
	);

	return (
		<div className='bg-white px-4 py-6'>
			<div className='flex items-center justify-between w-full'>
				<div className='flex items-center'>
					<div className='lg:hidden block'>
						<button
							className='flex flex-col h-12 w-12 justify-center items-center group mr-4'
							onClick={() => setIsOpenSidebar(!isOpenSidebar)}
						>
							<div
								className={`${genericHamburgerLine} ${
									isOpenSidebar
										? 'rotate-45 translate-y-3 opacity-50 group-hover:opacity-100'
										: 'opacity-50 group-hover:opacity-100'
								}`}
							/>
							<div
								className={`${genericHamburgerLine} ${
									isOpenSidebar ? 'opacity-0' : 'opacity-50 group-hover:opacity-100'
								}`}
							/>
							<div
								className={`${genericHamburgerLine} ${
									isOpenSidebar
										? '-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100'
										: 'opacity-50 group-hover:opacity-100'
								}`}
							/>
						</button>
					</div>
					<div className='flex items-center'>
						<img 
							alt=""
							src="/images/logo.svg"
							className='h-[50px]'
						/>
						<div className='ml-5'>
							<p className='cursor-default mb-0 font-semibold'>Susi Catering Medan</p>
							<p className='cursor-default mb-0'>Admin Dashboard</p>
						</div>
					</div>

				</div>
				<div className='flex gap-10'>
					<div className='flex items-center'>
						<p className='bg-teal-600 w-10 h-10 justify-center text-xl font-bold text-white flex items-center rounded-full cursor-default'>
							{isAuthenticated && user.name.charAt(0)}
						</p>
						<div className='ml-2'>
							<p className='cursor-default mb-0 font-semibold leading-none'>{isAuthenticated && user.name}</p>
							<p className='cursor-default capitalize text-sm mb-0 leading-1'>
								{isAuthenticated && user.role.split('-').join(' ')}
							</p>
						</div>
					</div>
					<button
						className='flex items-center justify-center group hover:bg-red-500 duration-300 rounded-lg px-4 py-2'
						onClick={onLogout}
					>
						<span className='mr-2 text-sm font-medium text-red-500 group-hover:text-white duration-300'>
							Logout
						</span>
						<IoIosLogOut className='text-red-500 group-hover:text-white duration-300' />
					</button>
				</div>
			</div>
		</div>
	);
};

export default Header;
