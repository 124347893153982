import MessageError from '@/components/form/MessageError';

export const messageError = (error) => {
	if (!error) {
		return '';
	}

	if (typeof error === 'string') {
		return error;
	}

	if (error?.message && typeof error.message === 'string') {
		return error.message;
	}

	return '';
};

const TextInput = ({
	label,
	labelClass = '',
	error,
	className = '',
	children,
}) => {
	return (
		<div className={`input-field ${className}`}>
			{label ? <label className={`text-sm font-semibold ml-1 mb-2 ${labelClass}`}>{label}</label> : null}
			{children}
			{error ? <MessageError error={messageError(error)} /> : null}
		</div>
	);
};

export default TextInput;
