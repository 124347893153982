import NegotiationConfirmationForm from "@/components/negotiation/NegotiationConfirmationForm";
import { API_URL } from "@/redux-client/api";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

const NegotiationConfirmation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = useCallback(async (booking_code) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${API_URL}/negotiation/${booking_code}`
      );
      if (response.status === 200) {
        const { data } = response.data;
        setData(data);
        // setValue("negotiation_date", {
        //   startDate: moment(data.negotiation_date, "DD/MM/YYYY").format('YYYY-MM-DD'),
        //   endDate: moment(data.negotiation_date, "DD/MM/YYYY").format('YYYY-MM-DD'),
        // })

        // const negotiationTime = data.negotiation_time.split(" ")[0];
        // setValue("negotiation_time", dayjs(negotiationTime, "hh.mm"));

        // const today = moment().format("DD/MM/YYYY");
        // const confirmAvailable = moment(data.negotiation_date, "DD/MM/YYYY").isSameOrAfter(moment(today, "DD/MM/YYYY"));
        // setIsConfirmAvailable(confirmAvailable);

        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data;
        setNotification({
          isError: true,
          title: "Gagal menyimpan data. Mohon coba beberapa saat lagi.",
          text: message,
        });
      }

      setLoading(false);

      setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (state && state.booking_code) fetchData(state.booking_code);
    return () => {};
  }, [fetchData, state]);

  return (
    <div>
        <div className="bg-white text-center w-full py-5 rounded-lg relative">
          <h1 className="text-2xl font-bold">Konfirmasi Pesanan</h1>
          <button
            onClick={() => navigate("/jadwal-negosiasi")}
            className="absolute left-[10px] top-[29%]"
          >
            <IoChevronBack size={30} />
          </button>
        </div>

      <div className="bg-white text-center w-full py-5 rounded-lg mt-6 p-5">
        <NegotiationConfirmationForm data={data} />
      </div>
    </div>
  );
};

export default NegotiationConfirmation;
