import { Navigate, Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import MainLayout from "@/components/layout";
import LoginPage from "@/pages/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import DashboardPage from "./pages/DashboardPage";
import NegotiationBooking from "./pages/NegotiationBooking";
import SettingsPage from "./pages/SettingsPage";
import BookingPage from "./pages/BookingPage";
import AdminPage from "./pages/AdminPage";
import AdminDetailsPage from "./pages/AdminDetailsPage";
import NegotiationBookingDetailsPage from "./pages/NegotiationBookingDetailsPage";
import NegotiationConfirmation from "./pages/NegotiationConfirmation";
import BookingDetailsPage from "./pages/BookingDetailsPage";
import PackagePage from "./pages/PackagePage";
import PackageDetailsPage from "./pages/PackageDetailsPage";

export default function App() {

  const { user, isAuthenticated } = useSelector((state) => state.auth);

	const homeRoute = useMemo(() => {
		let pathRoute = '/dashboard';
		if (isAuthenticated) {
			const { role } = user;
			if (
				role === "admin" || role === "super-admin"
			) {
				pathRoute = '/dashboard';
			}
		}
		return pathRoute;
	}, [isAuthenticated, user]);

  return (
    <MainLayout>
			<Routes>
				<Route
					path='/login'
					element={
						<AuthRoute>
							<LoginPage />
						</AuthRoute>
					}
				/>
				<Route
					path='/dashboard'
					element={
						<ProtectedRoute>
							<DashboardPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/jadwal-negosiasi'
					element={
						<ProtectedRoute>
							<NegotiationBooking />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/jadwal-negosiasi/:id'
					element={
						<ProtectedRoute>
							<NegotiationBookingDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/negotiation-confirmation'
					element={
						<ProtectedRoute>
							<NegotiationConfirmation />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/bookings'
					element={
						<ProtectedRoute>
							<BookingPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/bookings/details'
					element={
						<ProtectedRoute>
							<BookingDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/packages'
					element={
						<ProtectedRoute>
							<PackagePage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/packages/details'
					element={
						<ProtectedRoute>
							<PackageDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/admins'
					element={
						<ProtectedRoute>
							<AdminPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/admins/details'
					element={
						<ProtectedRoute>
							<AdminDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/settings'
					element={
						<ProtectedRoute>
							<SettingsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/'
					element={
						<Navigate
							to={homeRoute}
							replace
						/>
					}
				/>
			</Routes>
		</MainLayout>
  )
}