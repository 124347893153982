import ModalFooterButton from '@/components/common/ModalFooterButton';
import Notification from '@/components/common/Notification';
import SelectInput from '@/components/form/SelectInput';
import TextInput from '@/components/form/TextInput';
import { nopeResolver } from '@hookform/resolvers/nope';
import { API_URL } from '@/redux-client/api';
import { adminRoleOptions } from '@/static/data';
import axios from 'axios';
import Nope from 'nope-validator';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export const schemaAdmin = Nope.object().shape({
	email: Nope.string().email('Masukkan email dengan benar!').required('Masukkan email wajib!'),
	name: Nope.string().required('Masukkan nama wajib!'),
	role: Nope.string().required('Masukkan role wajib!'),
	phone: Nope.string().required('Masukkan no. hp wajib!'),
});

function FormAdmin({
	type = "add",
	dataFields,
	disabled = false,
	loading = false,
	className = '',
	closeModalHandler,
	submitHandler,
}) {
	const {
		register,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email: '',
			name: '',
			role: null,
			phone: '',
		},
		resolver: nopeResolver(schemaAdmin),
	});

	useEffect(() => {
		if (dataFields) {
			setValue('email', dataFields.email);
			setValue('name', dataFields.name);
			setValue('role', adminRoleOptions.find((option) => option.value === dataFields.role) ?? null);
			setValue('phone', dataFields.phone);
		}
	}, [dataFields, setValue]);

	return (
		<form>
			<div className={`grid grid-cols-1 gap-4 ${className}`}>
				<TextInput
					label='Email'
					error={errors?.email}
				>
					<input
						type='email'
						{...register('email')}
						disabled={loading || disabled || type==='edit'}
						aria-invalid={!!errors?.email}
					/>
				</TextInput>
				<TextInput
					label='Nama'
					error={errors?.name}
				>
					<input
						type='text'
						{...register('name')}
						disabled={loading || disabled}
						aria-invalid={!!errors?.name}
					/>
				</TextInput>
				<TextInput
					label='No. HP'
					error={errors?.phone}
				>
					<input
						type='tel'
						{...register('phone')}
						disabled={loading || disabled}
						aria-invalid={!!errors?.phone}
					/>
				</TextInput>
				<Controller
					control={control}
					name='role'
					render={({ field: { onChange, value } }) => (
						<SelectInput
							label='Role'
							disabled={loading || disabled}
							options={adminRoleOptions}
							value={value}
							error={errors?.role}
							onChange={onChange}
						/>
					)}
				/>
			</div>
			{!disabled ? (
				<ModalFooterButton
					loading={loading}
					onSubmit={handleSubmit(submitHandler)}
					onCancel={closeModalHandler}
				/>
			) : null}
		</form>
	);
}

export function AddFormAdmin({ className = '', closeModalHandler }) {
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);

	const onSubmit = useCallback(
		async (data) => {
			try {
				setIsLoading(true);
				const payload = {
					...data,
					role: data.role?.value,
				};
				const response = await axios.post(`${API_URL}/auth-crm/create`, payload);
				if (response.status === 200) {
					const { message } = response.data;

					setNotification({
						isError: false,
						title: 'Sukses',
						text: message,
					});

					setIsLoading(false);

					setTimeout(() => {
						setNotification(null);

						if (typeof closeModalHandler === 'function') {
							closeModalHandler();
						}
					}, 2000);
				}
			} catch (error) {
				console.log('error', error);
				if (error?.response?.data) {
					const { message } = error.response.data;
					setNotification({
						isError: true,
						title: 'Error',
						text: message,
					});
				}

				setIsLoading(false);

				setTimeout(() => {
					setNotification(null);
				}, 2000);
			}
		},
		[closeModalHandler]
	);

	return (
		<>
			{notification ? (
				<div className='mb-5'>
					<Notification notification={notification} />
				</div>
			) : null}
			<FormAdmin
				loading={isLoading}
				className={className}
				closeModalHandler={closeModalHandler}
				submitHandler={onSubmit}
			/>
		</>
	);
};

export function EditFormAdmin({ data, disabled = false, className = '', closeModalHandler }) {
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);

	const onSubmit = useCallback(
		async (dataForm) => {
			try {
				setIsLoading(true);
				const payload = {
					...dataForm,
					role: dataForm.role?.value,
				};
				const response = await axios.post(`${API_URL}/admin/edit`, payload);
				if (response.status === 200) {
					const { message } = response.data;

					setNotification({
						isError: false,
						title: 'Sukses',
						text: message,
					});

					setIsLoading(false);

					setTimeout(() => {
						setNotification(null);

						if (typeof closeModalHandler === 'function') {
							closeModalHandler();
						}
					}, 2000);
				}
			} catch (error) {
				console.log('error', error);
				if (error?.response?.data) {
					const { message } = error.response.data;
					setNotification({
						isError: true,
						title: 'Error',
						text: message,
					});
				}

				setIsLoading(false);

				setTimeout(() => {
					setNotification(null);
				}, 2000);
			}
		},
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[closeModalHandler]
	);

	return (
		<>
			{notification ? (
				<div className='mb-5'>
					<Notification notification={notification} />
				</div>
			) : null}
			<FormAdmin
				type="edit"
				dataFields={data}
				disabled={disabled}
				loading={isLoading}
				className={className}
				closeModalHandler={closeModalHandler}
				submitHandler={onSubmit}
			/>
		</>
	);
};
