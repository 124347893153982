import { BeatLoader, MoonLoader } from 'react-spinners';

const Loading = ({ size, type, loading, parentClass = '', className = '' }) => {
	return (
		<div className={`flex gap-1 items-center justify-center ${type === 'beat-loader' && 'flex-col'} ${parentClass}`}>
			{type === 'beat-loader' ? (
				<BeatLoader
					cssOverride={{ display: 'block' }}
					className={className}
					color='#EA580C'
					loading={loading}
					size={size}
				/>
			) : (
				<MoonLoader
					cssOverride={{ display: 'block' }}
					className={className}
					color='#EA580C'
					loading={loading}
					size={size}
				/>
			)}
			Mohon Tunggu...
		</div>
	);
};

export default Loading;
