import TableAdmin from '@/components/admin/TableAdmin';

const AdminPage = () => {
	return (
		<div className='bg-gray-100'>
			<div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Daftar Admin</h1>
			</div>
			{/* SEE ADMIN LIST */}
			<TableAdmin />
		</div>
	);
};

export default AdminPage;
