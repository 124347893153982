import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Nope from 'nope-validator';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { nopeResolver } from '@hookform/resolvers/nope';

import { API_URL } from '@/redux-client/api';
import { setCredentials } from '@/redux-client/slices/authSlice';

import TextInput from '@/components/form/TextInput';
import Loading from '@/components/common/Loading';
import Notification from '@/components/common/Notification';
import Button from '@/components/common/Button';
import MessageError from '@/components/form/MessageError';

const schemaLogin = Nope.object().shape({
	email: Nope.string().email('Masukkan email dengan benar!').required('Masukkan email wajib!'),
	password: Nope.string().required('Masukkan password wajib!'),
});

const LoginPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
		resolver: nopeResolver(schemaLogin),
	});

	const onLogin = useCallback(async (data) => {
		try {
			setIsLoading(true);
			const payload = data;
			const response = await axios.post(`${API_URL}/auth-crm/login`, payload);
			if (response.status === 200) {
				const { user, token } = response.data;
				dispatch(setCredentials({ user, token: token.key }));
				setIsLoading(false);
				navigate('/dashboard');
			}
		} catch (error) {
			if (error?.response?.data) {
				const { message } = error.response.data;
				setNotification({
					isError: true,
					title: 'Login gagal',
					text: message,
				});
				setError('email', { type: 'custom' }, { shouldFocus: false });
				setError('password', { type: 'custom' }, { shouldFocus: false });
			} else {
        setNotification({
					isError: true,
					title: 'Network Error!',
					text: "Gagal menghubungkan ke server.",
				});
      }
			setTimeout(() => {
				setNotification(null);
			}, 2000);
			setIsLoading(false);
		}
	}, [dispatch, navigate, setError]);

	return (
		<div className='h-screen w-screen flex items-center justify-center bg-gray-100'>
			<form className='bg-white p-6 min-w-[350px] md:min-w-[450px] rounded-xl shadow-md'>
				<div className='w-full mb-6'>
          <img 
            src="/images/logo.svg"
            alt="Susi Catering Medan Logo"
            className='w-[100px] h-auto mx-auto mb-10'
          />
					<h1 className='text-center text-2xl font-bold'>Susi Catering Medan</h1>
					<h2 className='text-center text-xl font-semi mt-1 mb-10'>Admin Dashboard</h2>
				</div>
				<div className='grid grid-cols-1 gap-4'>
					<TextInput label='Email'>
						<input
							type='email'
							{...register('email')}
							aria-invalid={!!errors?.email}
						/>
						<MessageError error={errors?.email?.message} />
					</TextInput>
					<TextInput label='Password'>
						<input
							type='password'
							{...register('password')}
							aria-invalid={!!errors?.password}
						/>
						<MessageError error={errors?.password?.message} />
					</TextInput>
				</div>
				<Notification notification={notification} />
				<div className='mt-6'>
					{isLoading ? (
						<Loading
							size={20}
							loading={isLoading}
							type='circle'
							className='text-teal-600'
						/>
					) : (
						<Button
							type='submit'
							buttonSize='xl'
							onClick={handleSubmit(onLogin)}
							parentClass='w-full'
							className='w-full justify-center bg-orange-500'
						>
							Login
						</Button>
					)}
				</div>
				<div className='text-center mt-5'>
					<small className='cursor-default -space-x-1'>Powered by Karyakuni Indonesia</small>
				</div>
			</form>
		</div>
	);
};

export default LoginPage;
