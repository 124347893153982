import Loading from "@/components/common/Loading";
import Notification from "@/components/common/Notification";
import EditFormPackage from "@/components/package/EditFormPackage";
import { API_URL } from "@/redux-client/api";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

const PackageDetailsPage = () => {
  const { state } = useLocation();
	const navigate = useNavigate();

	const [packageData, setPackageData] = useState(null);
	const [pageLoading, setPageLoading] = useState(false);
	const [pageNotification, setPageNotification] = useState(null);
	const [disableEditForm, setDisableEditForm] = useState(true);

	const fetchPackageDetails = useCallback(async () => {
		setPageLoading(true);

		try {
			const response = await axios.get(`${API_URL}/package-admin/${state.package_id}`);

			if (response.status === 200) {
        const { data } = response.data;
				setPackageData(data);
				setPageLoading(false);
			} else {
        setPageNotification({
          isError: true,
          title: 'Error',
          text: "Internal server error.",
        });
      }
		} catch (error) {
			if (error?.response?.data) {
				const { message } = error.response.data;
				setPageNotification({
					isError: true,
					title: 'Error',
					text: message,
				});
			}

			setPageLoading(false);

			setTimeout(() => {
				setPageNotification(null);
			}, 2000);
		}
	}, [state]);

	useEffect(() => {
		fetchPackageDetails();
	}, [fetchPackageDetails, disableEditForm]);

  return (
    <div>
			<div className='bg-white text-center w-full py-5 rounded-lg relative'>
        <button
					onClick={() => navigate('/packages')}
					className='absolute left-4 flex items-center top-3 py-2 rounded-xl text-black opacity-80 hover:opacity-100 transition-all ease-in-out duration-300'
				>
					<HiChevronLeft size={30} />
				</button>
				<h1 className='text-2xl font-bold'>Paket {packageData?.name}</h1>
			</div>
			<Notification notification={pageNotification} />
			<div className='bg-white p-10 rounded-xl mt-10'>
				{pageLoading ? (
					<div className='py-10'>
						<Loading
							type='beat-loader'
							loading={pageLoading}
							size={24}
						/>
					</div>
				) : (
					<>
						{packageData ? (
							<EditFormPackage
                packageId={packageData._id}
								menuList={packageData.menus}
								disabled={disableEditForm}
								className='sm:grid-cols-3'
                fetchPackageDetails={fetchPackageDetails}
							/>
						) : null}
					</>
				)}
			</div>
		</div>
  );
};

export default PackageDetailsPage;
