import React from 'react'

const DashboardPage = () => {
  return (
    <div className='bg-white h-full flex items-center justify-center flex-col rounded-lg p-10'>
      <img 
        alt="under construction"
        src="/images/under-construction.jpeg"
      />
      <h1 className='font-bold text-4xl mt-10'>This feature is under construction...</h1>
    </div>
  )
}

export default DashboardPage