// type Props = {
// 	notification: NotificationType | null | undefined;
// 	className?: string;
// };

const Notification = ({ notification, className = '' }) => {
	if (!notification) return null;
	return (
		<div
			className={`mt-7 px-4 py-3 rounded-lg text-white ${
				notification.isError ? 'bg-red-500' : 'bg-green-500'
			} ${className}`}
		>
			<p className='font-bold text-md'>{notification?.title}</p>
			<p className='text-md'>{notification?.text}</p>
		</div>
	);
};

export default Notification;
