import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist';
import thunk from 'redux-thunk';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
};

const rootReducer = combineReducers({
	auth: authReducer,
});

const _persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: _persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
});

export const persistor = persistStore(store);
